<template>
  <div class="project_detail">
    <div class="detail_wrapper manage-wrapper">
      <div class="project-detail-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="沙龙详情" name="first">
            <el-form ref="form" :model="form" label-width="180px">

              <el-form-item label="沙龙名称：" :required="true">
                {{form.name||''}}
              </el-form-item>

              <el-form-item label="沙龙简介：">
                <p class="introduce" v-html="form.courseExplain||'无'"></p>
              </el-form-item>

              <el-form-item label="是否为精品课：" :required="true">
                {{form.boutique ? '是':'否'}}
              </el-form-item>

              <div class="teahcers flex-align" v-if="form.teacherInfo">
                <el-form-item label="授课老师：" :required="true">
                  {{form.teacherInfo.name || '未知'}}
                </el-form-item>
                <el-form-item label="助教老师：">
                  {{form.assistantInfo.name}}
                </el-form-item>
              </div>

              <el-form-item label="主题封面：">
                <p v-if="form.cover === ''">无</p>
                <img :src="downloadURL + form.cover" alt="" class="cover" v-else />
              </el-form-item>

              <el-form-item class="el-form-item-btns" label-width="0">
                <el-button type="primary" @click="goEdit">编辑</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName: "first",
        form: {}, //沙龙信息
      };
    },
    methods: {
      // 跳转编辑页
      goEdit() {
        this.$router.push({
          path: `/salon/edit/${this.$route.params.id}`,
          query: {
            proId: this.$route.query.proId,
            breadNumber: 4,
          }
        });
      },
      // 通过id查询沙龙信息
      async getSalonDetail() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.getSalonDetail(id);
        this.form = resData.data;
        console.log('回显数据', this.form)
      },
    },
    mounted() {
      window.scroll(0, 0)
      this.getSalonDetail();
    },
  };
</script>

<style lang="less" scoped>
  @import "../manage_project/less/manage-project.less";

  .project-detail-container {
    .introduce {
      padding-right: 40px;
      word-wrap: break-word;
      word-break: break-all;

    }

  }
</style>